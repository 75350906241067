<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent width="900px">
      <template v-slot:activator="{ on }">
        <v-btn color="#FB8C00" text v-on="on">
          <slot>
            Политика конфиденциальности
          </slot>
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span style="word-break: normal" class="headline"
            >ЗАЩИТА КОНФИДЕНЦИАЛЬНОСТИ ПЕРСОНАЛЬНЫХ ДАННЫХ</span
          >
        </v-card-title>
        <v-card-text class="text-justify"
          >Политика защиты конфиденциальности персональных данных (здесь и далее «Политика»)
          действует в отношении всех Ваших персональных данных, которые Вы размещаете на данном
          сайте (здесь и далее «Сайт») либо высылаете нам с целью получения услуг или участия в
          мероприятиях (здесь и далее «Данные») в следующем порядке: <br />
          <br />
          1. Ваши Данные собираются и хрянятся в наших базах данных на основе действующего
          законодательства Республики Италии, в часности Постановления Европейского Союза 2016/679 в
          следующих целях: Вашей идентификации, создания учетной записи для совершения покупок,
          предоставления Вам доступа к личному кабинету, установления с Вами обратной связи,
          направления уведомлений, проверки получения платежей, предоставления Вам эффективной
          клиентской и технической поддержки. <br />
          2. Настоящая Политика применима только к Данным, обрабатываемым в ходе использования Сайта
          или участия в мероприятиях. Мы не контролируем и не несем ответственность за обработку
          Данных третьими лицами, услугами которых Вы можете воспользоваться, перейдя по ссылкам,
          доступным внутри Сайта. <br />
          3. Мы не проверяем достоверность Вами предоставляемых Данных. Мы исходим из того, что Вы
          предоставляете достоверные Данные в полном объёме и вносите изменения в случае их
          изменения. В случае предоставления недостоверных Данных или их предоставления в неполном
          объёме, мы не гарантируем предоставления услуг или участия в мероприятиях. <br />
          4. Мы собираем и храним только те Данные, которые необходимы для исполнения наших с Вами
          договорённостей о получении услуг или участия в мероприятиях, за исключением случаев,
          когда законодательством предусмотрено обязательное хранение Данных в течение определенного
          законом срока. <br />
          5. Обработка Данных осуществляется исключительно законным образом и без использования
          средств автоматизации. Вы соглашаетесь с тем, что мы будем вправе передать ваши Данные
          третьим лицам исключительно в соответствии с пунктом 4 выше. <br />
          6. Вы можете в любой момент изменить (обновить, дополнить) или удалить предоставленные
          Вами Данные или их часть, воспользовавшись функцией редактирования Данных на Сайте, в
          случае, если она предусмотрена Сайтом, либо направив соответствующий запрос по электронной
          почте на следующий адрес: support@metamorfosi.eu, если такая функция отсутствует. <br />
          7. Все предложения или вопросы по поводу настоящей Политики направляйте по следующему
          адресу электронной почты: support@metamorfosi.eu <br />
          8. Использование Вами Сайта или регистрация на участие в мероприятии означает согласие с
          настоящей Политикой конфиденциальности и условиями обработки Ваших персональных Данных. В
          случае несогласия с условиями Политики Вы должны прекратить использование Сайта и/или
          отменить участие в мероприятии, а также уведомить нас об этом по следующему адресу
          электронной почты: support@metamorfosi.eu .В таком случае, мы удалим Ваши данные в
          требуемом законом объёме. <br />
          9. Данная Политика действует бессрочно до замены ее новой версией.</v-card-text
        >
        <v-card-actions>
          <v-spacer />
          <v-btn color="#FB8C00" text @click="dialog = false">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: 'DialogPrivacyPolicy',
  props: {
    dialogTrue: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialog: this.dialogTrue
    }
  }
}
</script>

<style scoped></style>
